import {useState, useContext} from 'react';

import AuthContext from '../../../store/auth-context';

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../../../helpers/analytics-helpers';

import Logo from '../../UIElements/Logo/Logo';
import CartIcon from '../../UIElements/CartIcon/CartIcon';
import Navbar from '../Navbar/Navbar';

import styles from './MainNavbar.module.css';


const MainNavbar = props => {

	const authCtx = useContext(AuthContext);
	
	const [isNavCollapsed, setIsNavCollapsed] = useState(true);
	const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

	const path = window.location.pathname;

	// Handlers for analytics events
	const navItetemAnalyticsHandler = name => {
		ReactGA.event({
    	category: eventCategories.ENGAGEMENT, 
    	action: eventNames.CLICKED_ON_NAV_ITEM,
    	label: name
    })
	}

	const handleSignInClick = () => {
		props.fireSignInModal();
		navItetemAnalyticsHandler('signIn');
		ReactGA.event({
    	category: eventCategories.AUTHENTICATION, 
    	action: eventNames.OPENED_SIGN_IN,
    });
	}

	const handleBecomeAMemberClick = () => {
		navItetemAnalyticsHandler('becomeAMember');
		ReactGA.event({
    	category: eventCategories.ENGAGEMENT, 
    	action: eventNames.CLICKED_NAV_CTA,
    });
	}

	const handleLogoutClick = () => {
		authCtx.onLogout();
		navItetemAnalyticsHandler('logout');
	}


	const navItems = [
		{
			id: 0,
			copy: 'Home',
			type: 'link',
			destination: '/',
			showTo: 'everyone', 
			active: path === '/' ? true : false,
			clickHandler: () => navItetemAnalyticsHandler('home')
		},
		{
			id: 1,
			copy: 'Our Story',
			type: 'link',
			destination: '/our-story',
			showTo: 'everyone', 
			active: path === '/our-story' ? true : false,
			clickHandler: () => navItetemAnalyticsHandler('ourStory')
		},
		{
			id: 2,
			copy: 'Our Olive Oils',
			type: 'link',
			destination: '/our-olive-oils',
			showTo: 'everyone',
			active: path === '/our-olive-oils' ? true : false,
			clickHandler: () => navItetemAnalyticsHandler('ourOliveOils')
		},
		{
			id: 3,
			copy: 'Our Plans',
			type: 'link',
			destination: '/become-a-member',
			showTo: 'authenticated',
			active: path === '/become-a-member' ? true : false,
			clickHandler: () => navItetemAnalyticsHandler('becomeAMember')
		},
		{
			id: 4,
			copy: 'Academy',
			type: 'link',
			destination: '/academy',
			showTo: 'everyone',
			active: path === '/academy' ? true : false,
			clickHandler: () => navItetemAnalyticsHandler('academy')
		},
		{
			id: 5,
			copy: 'Sign In',
			type: 'link-btn',
			showTo: 'guests',
			clickHandler: () => handleSignInClick() 
		}, 
		{
			id: 6,
			copy: 'Become A Member',
			type: 'btn-link',
			destination: '/become-a-member',
			showTo: 'guests',
			active: path === '/become-a-member' ? true : false,
			clickHandler: () => handleBecomeAMemberClick() 
		},
		{
			id: 7,
			copy: 'Your Account',
			type: 'link',
			destination: '/account',
			showTo: 'authenticated',
			active: path === '/account' ? true : false,
			clickHandler: () => navItetemAnalyticsHandler('yourAccount')
		},
		{
			id: 8,
			copy: 'Logout',
			type: 'link-btn',
			showTo: 'authenticated',
			clickHandler: () => handleLogoutClick()
		}
	];

	const backgroundStyles = {
		background: `#445D5C`,
		backgroundSize: 'cover',
  	backgroundRepeat: 'no-repeat',
	}

	const blackStyles = {
		background: `#212721`,
		backgroundSize: 'cover',
  	backgroundRepeat: 'no-repeat',
	}

	const transparentStyles = {
		position: 'absolute',
		width: '100%'
	}

	let menuStyles = {
		...backgroundStyles,
		backgroundPosition: '-90px 0px'
	}

	const goldTogglerStyles ={
		borderColor: `#c08329`,
	}

	let navStyles = backgroundStyles;
	let logoToUse = <Logo />; 
	let isGold = false;
	let togglerIconStyles = null;
	let togglerBtnStyles = null;

	if (props.transparentNavbar) navStyles = transparentStyles;
	if (props.blackNavbar) {
		navStyles = blackStyles;
		menuStyles = {
			...blackStyles,
			backgroundPosition: '-90px 0px'
			}
		logoToUse = <Logo gold/>; 
		isGold = true;
		togglerIconStyles = styles.GoldTogglerIcon;
		togglerBtnStyles = styles.GoldTogglerBtn;
	}

	return (
		<section style={navStyles}>
			<nav className='navbar navbar-dark'>
				<div className='container'>
					{logoToUse}			
					<div className='d-flex d-lg-none align-items-center'>	
						<button 
							className={`custom-toggler navbar-toggler ${togglerBtnStyles}`} 
							type='button' 
							data-toggle='collapse'
							data-target='#navbarExpand' 
							aria-controls='navbarExpand' 
							aria-expanded={!isNavCollapsed ? true : false} 
							aria-label='Toggle navigation' 
							onClick={handleNavCollapse}
						>
							<span className={`navbar-toggler-icon ${togglerIconStyles}`}></span>
	      		</button>
	      		<CartIcon isGold={isGold}/>
      		</div>
      		<div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} style={menuStyles} id='navbarExpand'>
		        <Navbar isGold={isGold} authenticated={authCtx.isLoggedIn} navItems={[...navItems]} />
	      	</div>
	      	<div className='d-none d-lg-flex align-items-center'>
	      		<Navbar isGold={isGold} authenticated={authCtx.isLoggedIn} navItems={[...navItems]} />
	      		<CartIcon isGold={isGold} shiftUp />
	      	</div>

				</div>
			</nav>
		</section>
	)
}

export default MainNavbar;