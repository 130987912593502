import {useContext} from 'react';

import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import Hero from './Hero/Hero';

import CartContext from '../../store/cart-context';
import PromotionCodeContext from '../../store/promotion-code-context';

const HolidayCollection = () => {
	const cartCtx = useContext(CartContext);
	const promoCodeCtx = useContext(PromotionCodeContext);

	const addToCart = ()=>cartCtx.addItem('holidayTastingCollection');

	return (
		<MainLayout blackNavbar bottomCTA='default'>
			<Hero addToCart={addToCart}/>
			{/*
			<div className='container'>
				<div className='row'>
					<div className='col-12'>
						<h3>Holiday Collection goes here</h3>
					</div>
				</div>
			</div>
			*/}
		</MainLayout>
	)
}

export default HolidayCollection;