import { createContext, useState, useContext } from 'react';
import PromotionCodeContext from './promotion-code-context';

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../helpers/analytics-helpers';

const CartContext = createContext({
	isDrawerShown: '',
	isDrawerClosed: '',
	quantities: '',
	shippingPrice: '',
	toggleDrawer: boolean =>{},
	addItem: item => {},
	removeItem: item => {},
	changeItemCount: (item, newQuantity) => {},
	sumBottles: ()=>{},
	getItemListPrice: item=>{},
	getItemDiscountedPrice: (item, percentOff)=>{},
	getItemListPriceAsString: item=>{},
	getItemDiscountedPriceAsString: (item, percentOff)=>{},
	getItemTotalPrice: (item, quantity, percentOff)=>{},
	getItemName: item=>{},
	getItemImage: item=>{},
	hasMembership: items =>{},
	hasDiscountElligibleItem: () => {},
	hasFreeShipping: () =>{},
	resetCart: ()=>{}
});

export const CartContextProvider = props => {

	const promoCodeCtx = useContext(PromotionCodeContext);

	const [isDrawerShown, setIsDrawerShown] = useState(false);
	const [isDrawerClosed, setIsDrawerClosed] = useState(null);
	const [quantities, setQuantities] = useState({
		premium: localStorage.getItem('TrueOlivePremiumCount') ? Number(localStorage.getItem('TrueOlivePremiumCount')) : 0,
		luxe: localStorage.getItem('TrueOliveLuxeCount') ? Number(localStorage.getItem('TrueOliveLuxeCount')) : 0,
		privateReserve: localStorage.getItem('TrueOlivePrivateReserveCount') ? Number(localStorage.getItem('TrueOlivePrivateReserveCount')) : 0,
		tastingCollection: localStorage.getItem('TrueOliveTastingCollectionCount') ? Number(localStorage.getItem('TrueOliveTastingCollectionCount')) : 0,
		holidayTastingCollection: localStorage.getItem('TrueOliveHolidayTastingCollectionCount') ? Number(localStorage.getItem('TrueOliveHolidayTastingCollectionCount')) : 0,
		everydayLuxury: localStorage.getItem('TrueOliveEverydayLuxuryCount') ? Number(localStorage.getItem('TrueOliveEverydayLuxuryCount')) : 0,
		refinedPalate: localStorage.getItem('TrueOliveRefinedPalateCount') ? Number(localStorage.getItem('TrueOliveRefinedPalateCount')) : 0,
		trueConnoisseur: localStorage.getItem('TrueOliveTrueConnoisseurCount') ? Number(localStorage.getItem('TrueOliveTrueConnoisseurCount')) : 0,
		premium750: localStorage.getItem('TrueOlivePremium750Count') ? Number(localStorage.getItem('TrueOlivePremium750Count')) : 0,
		luxe750: localStorage.getItem('TrueOliveLuxe750Count') ? Number(localStorage.getItem('TrueOliveLuxe750Count')) : 0,
		privateReserve750: localStorage.getItem('TrueOlivePrivateReserve750Count') ? Number(localStorage.getItem('TrueOlivePrivateReserve750Count')) : 0,
	});
	const shippingPrice = 10;

	const toggleDrawer = boolean => {
		setIsDrawerShown(boolean);
		setIsDrawerClosed(!boolean);
		// Reset drawerClosed to null when hiding to avoid the close animation repeating itself 
		if (!boolean && isDrawerShown) resetIsDrawerClosed();
	}

	const resetIsDrawerClosed = () => setTimeout(()=>{setIsDrawerClosed(null)},500)

	const addItem = item => {

		if (quantities[item] === 0) {
			ReactGA.event({
	    	category: eventCategories.INTENT, 
	    	action: eventNames.ADDED_ITEM_TO_CART,
	    	label: item
	    })
		} else {
			ReactGA.event({
	    	category: eventCategories.INTENT, 
	    	action: eventNames.INCREMENTED_CART_ITEM,
	    	label: item
	    })
		}

		const newQuantity = quantities[item]+1;
		changeItemCount(item, newQuantity);
		toggleDrawer(true);
	}

	const removeItem = item => {
		if(quantities[item]>=1) {
			if (quantities[item] === 1) {
				ReactGA.event({
		    	category: eventCategories.INTENT, 
		    	action: eventNames.REMOVED_ITEM_TO_CART,
		    	label: item
		    })
			} else {
				ReactGA.event({
		    	category: eventCategories.INTENT, 
		    	action: eventNames.DECREMENTED_CART_ITEM,
		    	label: item
		    })
			}
			const newQuantity = quantities[item]-1;
			changeItemCount(item, newQuantity);
			toggleDrawer(true);
		}
	}

	const changeItemCount = (item, newQuantity) => {
		const newQuantities = {...quantities};
		newQuantities[item] = newQuantity;
		setQuantities(newQuantities);
		adjustLocalStorageCounts(item, newQuantity)
	}

	const sumBottles = ()=> {
		let sum = 0;
		Object.keys(quantities).map(key=>sum += quantities[key]);
		return sum;
	}

	const getItemListPrice = item => {
		if (item === 'premium') return 24;
		if (item === 'luxe') return 34;
		if (item === 'privateReserve') return 49;
		if (item === 'tastingCollection') return 79;
		if (item === 'holidayTastingCollection') return 49;
		if (item === 'everydayLuxury') return 49;
		if (item === 'refinedPalate') return 69;
		if (item === 'trueConnoisseur') return 79;
		if (item === 'premium750') return 29;
		if (item === 'luxe750') return 34;
		if (item === 'privateReserve750') return 40;
		return false;
	}

	const getItemDiscountedPrice = (item, percentOff) => {
		const priceAdjuster = percentOff ? 1-(percentOff/100) : 1;
		if (
			item === 'premium' || 
			item === 'luxe' || 
			item === 'privateReserve' ||
			item === 'tastingCollection'
		) {
			let price = getItemListPrice(item) * priceAdjuster;
			if (price % 1 !== 0) return price.toFixed(2);
			return price;
		}

		return getItemListPrice(item);
	}

	const getItemListPriceAsString = item=>{
		if (item === 'premium') return `$${getItemListPrice('premium')}/bottle`;
		if (item === 'luxe') return `$${getItemListPrice('luxe')}/bottle`;
		if (item === 'privateReserve') return `$${getItemListPrice('privateReserve')}/bottle`;
		if (item === 'tastingCollection') return `$${getItemListPrice('tastingCollection')}/set`;
		if (item === 'holidayTastingCollection') return `$${getItemListPrice('holidayTastingCollection')}/set`;
		if (item === 'everydayLuxury') return `$${getItemListPrice('everdayLuxury')}/bottle`;
		if (item === 'refinedPalate') return `$${getItemListPrice('refinedPalate')}/set`;
		if (item === 'trueConnoisseur') return `$${getItemListPrice('trueConnoisseur')}/set`;
		if (item === 'premium750') return `$${getItemListPrice('premium750')}/bottle`;
		if (item === 'luxe750') return `$${getItemListPrice('luxe750')}/bottle`;
		if (item === 'privateReserve750') return `$${getItemListPrice('privateReserve750')}/bottle`;
		return false;
	}

	const getItemDiscountedPriceAsString = (item, percentOff) => {
		if (item === 'premium') return `$${getItemDiscountedPrice('premium', percentOff)}/bottle`;
		if (item === 'luxe') return `$${getItemDiscountedPrice('luxe', percentOff)}/bottle`;
		if (item === 'privateReserve') return `$${getItemDiscountedPrice('privateReserve', percentOff)}/bottle`;
		if (item === 'tastingCollection') return `$${getItemDiscountedPrice('tastingCollection', percentOff)}/set`;
		if (item === 'holidayTastingCollection') return `$${getItemDiscountedPrice('holidayTastingCollection', percentOff)}/set`;
		if (item === 'everydayLuxury') return `$${getItemDiscountedPrice('everdayLuxury', percentOff)}/bottle`;
		if (item === 'refinedPalate') return `$${getItemDiscountedPrice('refinedPalate', percentOff)}/set`;
		if (item === 'trueConnoisseur') return `$${getItemDiscountedPrice('trueConnoisseur', percentOff)}/set`;
		if (item === 'premium750') return `$${getItemDiscountedPrice('premium750', percentOff)}/bottle`;
		if (item === 'luxe750') return `$${getItemDiscountedPrice('luxe750', percentOff)}/bottle`;
		if (item === 'privateReserve750') return `$${getItemDiscountedPrice('privateReserve750', percentOff)}/bottle`;
		return false;
	}

	const getItemTotalPrice = (item, quantity, percentOff) => {
		let recurringCopy = '';
		if (item === 'everydayLuxury' || item === 'refinedPalate' || item === 'trueConnoisseur') {
			recurringCopy='/m'
		}
		const price = getItemDiscountedPrice(item, percentOff); 
		return `$${price*quantity}${recurringCopy}`;
	}

	const getItemName = item=>{
		if (item === 'premium') return 'Premium';
		if (item === 'luxe') return 'Luxe';
		if (item === 'privateReserve') return 'Private Reserve';
		if (item === 'tastingCollection') return 'Tasting Collection';
		if (item === 'holidayTastingCollection') return 'Holiday Tasting Collection';
		if (item === 'everydayLuxury') return 'Everyday Luxury';
		if (item === 'refinedPalate') return 'Refined Palate';
		if (item === 'trueConnoisseur') return 'True Connoisseur';
		if (item === 'premium750') return 'Premium - 750ml';
		if (item === 'luxe750') return 'Luxe - 750ml';
		if (item === 'privateReserve750') return 'Private Reserve - 750ml';
		return false;
	}

	const getItemImage = item => {
		if (item === 'premium') return 'https://dev.trueolive.co/premium.webp';
		if (item === 'luxe') return 'https://dev.trueolive.co/luxe.webp';
		if (item === 'privateReserve') return 'https://dev.trueolive.co/private-reserve.webp';
		if (item === 'tastingCollection') return 'https://dev.trueolive.co/tasting-collection.webp';
		if (item === 'holidayTastingCollection') return 'https://dev.trueolive.co/tasting-collection.webp';
		if (item === 'everydayLuxury') return 'https://dev.trueolive.co/everyday-luxury.webp';
		if (item === 'refinedPalate') return 'https://dev.trueolive.co/refined-palate.webp';
		if (item === 'trueConnoisseur') return 'https://dev.trueolive.co/true-connoisseur.webp';
		if (item === 'premium750') return 'https://dev.trueolive.co/premium-750.webp';
		if (item === 'luxe750') return 'https://dev.trueolive.co/luxe-750.webp';
		if (item === 'privateReserve750') return 'https://dev.trueolive.co/private-reserve-750.webp';
		return false;
	}

	const hasMembership = items => {
		let hasMemb = false;

		for (const key in items){

			if(items[key].id === 'everydayLuxury' || items[key].id === 'refinedPalate' || items[key].id === 'trueConnoisseur') {
				if(items[key].quantity > 0) hasMemb  = true;
			}
		}

		return hasMemb;
	}

	const hasDiscountElligibleItem = () => {
		if (quantities.premium > 0 || quantities.luxe > 0 || quantities.privateReserve >0 || quantities.tastingCollection > 0){
			return true;
		}
		return false;
	}

	const hasFreeShipping = () => {
		let hasFreeS = false;

		if (
			quantities.tastingCollection > 0 ||
			quantities.holidayTastingCollection > 0 ||
			quantities.everydayLuxury > 0 || 
			quantities.refinedPalate > 0 || 
			quantities.trueConnoisseur >0 ||
			sumBottles() > 1 
		) {
			hasFreeS = true;
		}

		return hasFreeS;
	}

	const resetCart = ()=>{
		setQuantities({
			premium: 0,
			luxe: 0,
			privateReserve: 0,
			tastingCollection: 0,
			holidayTastingCollection: 0,
			everydayLuxury: 0,
			refinedPalate: 0,
			trueConnoisseur: 0,
			premium750: 0,
			luxe750: 0,
			privateReserve750: 0,
		});

		localStorage.removeItem('TrueOlivePremiumCount');
		localStorage.removeItem('TrueOliveLuxeCount');
		localStorage.removeItem('TrueOlivePrivateReserveCount');
		localStorage.removeItem('TrueOliveTastingCollectionCount');
		localStorage.removeItem('TrueOliveHolidayTastingCollectionCount');
		localStorage.removeItem('TrueOliveEverydayLuxuryCount');
		localStorage.removeItem('TrueOliveRefinedPalateCount');
		localStorage.removeItem('TrueOliveTrueConnoisseurCount');
		localStorage.removeItem('TrueOlivePremium750Count');
		localStorage.removeItem('TrueOliveLuxe750Count');
		localStorage.removeItem('TrueOlivePrivateReserve750Count');
	} 

	const adjustLocalStorageCounts = (item, newQuantity) => {
		if (item === 'premium') localStorage.setItem('TrueOlivePremiumCount', newQuantity);
		if (item === 'luxe') localStorage.setItem('TrueOliveLuxeCount', newQuantity);
		if (item === 'privateReserve') localStorage.setItem('TrueOlivePrivateReserveCount', newQuantity);
		if (item === 'tastingCollection') localStorage.setItem('TrueOliveTastingCollectionCount', newQuantity);
		if (item === 'holidayTastingCollection') localStorage.setItem('TrueOliveHolidayTastingCollectionCount', newQuantity);
		if (item === 'everydayLuxury') localStorage.setItem('TrueOliveEverydayLuxuryCount', newQuantity);
		if (item === 'refinedPalate') localStorage.setItem('TrueOliveRefinedPalateCount', newQuantity);
		if (item === 'trueConnoisseur') localStorage.setItem('TrueOliveTrueConnoisseurCount', newQuantity);
		if (item === 'premium750') localStorage.setItem('TrueOlivePremium750Count', newQuantity);
		if (item === 'luxe750') localStorage.setItem('TrueOliveLuxe750Count', newQuantity);
		if (item === 'privateReserve750') localStorage.setItem('TrueOlivePrivateReserve750Count', newQuantity);
	}	

	return (
		<CartContext.Provider value={{
			isDrawerShown: isDrawerShown,
			isDrawerClosed: isDrawerClosed,
			toggleDrawer: toggleDrawer,
			quantities: quantities,
			shippingPrice: shippingPrice,
			addItem: addItem,
			removeItem: removeItem,
			changeItemCount: changeItemCount,
			getItemListPrice: getItemListPrice,
			getItemDiscountedPrice: getItemDiscountedPrice,
			getItemListPriceAsString: getItemListPriceAsString,
			getItemDiscountedPriceAsString: getItemDiscountedPriceAsString,
			getItemTotalPrice: getItemTotalPrice,
			getItemName: getItemName,
			getItemImage: getItemImage,
			sumBottles: sumBottles,
			hasMembership:hasMembership,
			hasDiscountElligibleItem: hasDiscountElligibleItem,
			hasFreeShipping: hasFreeShipping,
			resetCart: resetCart
		}}>
			{props.children}
		</CartContext.Provider>
	)
}

export default CartContext;