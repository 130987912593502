import styles from './LinkBtn.module.css';

const LinkBtn = props => {
	const isGold = props.isGold ? styles.Gold : null;

	return (
		<button className={`btn btn-link ${styles.LinkBtn} ${isGold}`} onClick={props.clickHandler}>
			{props.children}
		</button>
	)
} 

export default LinkBtn;