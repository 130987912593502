import React from 'react';
import ReactDOM from 'react-dom/client';
import {AuthContextProvider} from './store/auth-context';
import {UserContextProvider} from './store/user-context';
import {CartContextProvider} from './store/cart-context';
import {GuestContextProvider} from './store/guest-context';
import {InfluencerContextProvider} from './store/influencer-context';
import {OrdersContextProvider} from './store/orders-context';
import {AlertContextProvider} from './store/alert-context';
import {BetaContextProvider} from './store/beta-context';
import {RestaurantAccessContextProvider} from './store/restaurant-access-context';
import {PromotionCodeContextProvider} from './store/promotion-code-context';
import {RecipesContextProvider} from './store/recipes-context';
import {AffiliateContextProvider} from './store/affiliate-context';
import {BrowserRouter} from 'react-router-dom';

// Components
import App from './containers/App/App';

// Styles
import 'bootstrap/dist/css/bootstrap.min.css';

// Other
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

//<React.StrictMode>

root.render(  

    <BrowserRouter>
      <BetaContextProvider>
        <RestaurantAccessContextProvider>
          <AffiliateContextProvider>
            <PromotionCodeContextProvider>
              <AlertContextProvider>
                <InfluencerContextProvider>
                  <OrdersContextProvider>
                    <GuestContextProvider>
                      <CartContextProvider>
                        <UserContextProvider>
                          <AuthContextProvider>
                            <RecipesContextProvider>  
                              <App />
                            </RecipesContextProvider>
                          </AuthContextProvider>
                        </UserContextProvider>
                      </CartContextProvider>
                    </GuestContextProvider>
                  </OrdersContextProvider>
                </InfluencerContextProvider>
              </AlertContextProvider>
            </PromotionCodeContextProvider>
          </AffiliateContextProvider>  
        </RestaurantAccessContextProvider>  
      </BetaContextProvider>
    </BrowserRouter>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
