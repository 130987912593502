import {useState, useContext, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../../../helpers/analytics-helpers';

import AuthContext from '../../../store/auth-context';
import CartContext from '../../../store/cart-context';
import BetaContext from '../../../store/beta-context';
import RestaurantAccessContext from '../../../store/restaurant-access-context';
import PromotionCodeContext from '../../../store/promotion-code-context';

import Footer from '../../Navs/Footer/Footer';
import MainNavbar from '../../Navs/MainNavbar/MainNavbar';
import AcademyNavbar from '../../Navs/AcademyNavbar/AcademyNavbar';
import RestaurantsNavbar from '../../Navs/RestaurantsNavbar/RestaurantsNavbar';
import Navbar from '../../Navs/MainNavbar/MainNavbar';
import BottomCTA from '../../UIElements/BottomCTA/BottomCTA';
import SignInModal from '../../Modals/SignInModal/SignInModal';
import BetaModal from '../../Modals/BetaModal/BetaModal';
import SoftLaunchModal from '../../Modals/SoftLaunchModal/SoftLaunchModal';
import RestaurantAccessModal from '../../Modals/RestaurantAccessModal/RestaurantAccessModal';
import CartDrawer from '../../UIElements/CartDrawer/CartDrawer';
import Alert from '../../UIElements/Alert/Alert';

import styles from './MainLayout.module.css';

const MainLayout = props => {

	const authCtx = useContext(AuthContext); 
	const cartCtx = useContext(CartContext);
	const betaCtx = useContext(BetaContext);
	const restaurantAccessCtx = useContext(RestaurantAccessContext);
	const promoCodeCtx = useContext(PromotionCodeContext);

	const pathname= window.location.pathname;
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const promoCode = urlParams.get('promoCode');

	const [isSignInModalShown, setIsSignInModalShown] = useState(false);

	const handleSignIn = () => {
		setIsSignInModalShown(true);
		ReactGA.event({
  		category: eventCategories.ENGAGEMENT,
  		action: eventNames.OPENED_SIGN_IN
  	});
	}

	const handleSuccessfulSignIn = () => {
		setIsSignInModalShown(false);
		// TODO: Analytics event
	}

	useEffect(()=>{
		if(authCtx.isLoggedIn) handleSuccessfulSignIn();
	},[authCtx.isLoggedIn]);

	useEffect(()=>{
		if(cartCtx.sumBottles() === 0 && cartCtx.isDrawerShown) cartCtx.toggleDrawer(false);
	},[cartCtx]);

	const bottomCTACopy = props.bottomCTA ? props.bottomCTA : null;
	const bottomCTA = props.bottomCTA ? <BottomCTA copy={bottomCTACopy}/> : null;

	let navbar = <MainNavbar fireSignInModal={()=>handleSignIn()}/>;
	if (props.transparentNavbar) navbar = <MainNavbar transparentNavbar fireSignInModal={()=>handleSignIn()}/>;
	if (props.blackNavbar) navbar = <MainNavbar blackNavbar fireSignInModal={()=>handleSignIn()}/>;
	if (props.academy) navbar = <AcademyNavbar />
	if (restaurantAccessCtx.restaurantMode) navbar = <RestaurantsNavbar transparentNavbar fireSignInModal={()=>handleSignIn()}/>;

	let blockingModal = <BetaModal showModal={betaCtx.modalIsShown} hideModal={()=>betaCtx.setModalIsShown(false)} />;
	if (pathname === '/restaurants') {
		blockingModal = <RestaurantAccessModal showModal={restaurantAccessCtx.modalIsShown} hideModal={()=>restaurantAccessCtx.setModalIsShown(false)} />;
	}
	if(promoCodeCtx.softLaunchCode && !promoCode && pathname === '/'){
		blockingModal = <SoftLaunchModal showModal={promoCodeCtx.showSoftLaunchModal} hideModal={()=>promoCodeCtx.setShowSoftLaunchModal(false)} />
	}

	return (
		<div className={styles.MainLayout}>
			<div className={styles.ContentWrapper}>
				{navbar}
				{props.children}
				{bottomCTA}
			</div>
			<Footer />
			<SignInModal showModal={isSignInModalShown} hideModal={()=>setIsSignInModalShown(false)} />
			{blockingModal}
			<CartDrawer />
			<Alert />
		</div>
	)
}

export default MainLayout;