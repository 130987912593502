import { createContext, useState } from 'react';

const AffiliateContext = createContext({
	affiliateId: '',
});

export const AffiliateContextProvider = props => {
	const [affiliateId, setAffiliateId] = useState('');

	return (
		<AffiliateContext.Provider value={{
			affiliateId: affiliateId,
			setAffiliateId: setAffiliateId
		}}>
			{props.children}
		</AffiliateContext.Provider>
	);
}

export default AffiliateContext;