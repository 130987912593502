import FAQList from './FAQList/FAQList';
import SectionHeader from '../TextElements/SectionHeader/SectionHeader';

const FAQs = props => {

	const questions = [
		{
			id: 0,
			question: 'What makes your olive oil so good?',
			answer: `A number of unique factors coming together make our olive oil truly spectacular. 
			Our olive oil is produced on a single farm, in a region known for its high quality olive oil. 
			The farm has olive trees with ages ranging from a few decades old to over a thousand years old.
			The soil and the trees have been enriched by fires. Our family has been working with world 
			renowned consultants from Italy to ensure the highest quality farming and production practices. As a result the olive oil produced by this farm has 
			been awarded gold medals in international olive oil competitions on both sides of the Atlantic.`,
			showOnRestaurantsPage: true
		}, 
		{
			id: 1,
			question: 'Which olive oil is right for me?',
			answer: `We have three different bottles: Premium, Luxe, and Private Reserve. 
			If you are looking for a spectacular olive oil to cook, fry or bake with, you can’t go wrong with Premium. 
			Thanks to its high smoke point of 405°F you can fry with it up to 6 times without loosing any of the health benefits. 
			If you want to treat yourself to a finer olive oil to marinate, sauté, dress, or dip with use the Luxe. 
			It will up-level your salads, soups, hummus and more. Finally, if you were wondering what the best of the best 
			is like when it comes to olive oil, go with Private Reserve. You can dress and finish any dish with it: 
			salads, meat, pasta, chocolate mousse, icre-cream, and even coffee. It is guaranteed to impress your friends and 
			family. Still unsure? You can order a Tasting Collection and we'll send you a little bit of all three for you to try.`,
			showOnRestaurantsPage: true
		}, 
		{
			id: 2,
			question: 'How does shipping work?',
			answer: `We offer free shipping on all orders with 2 or more bottles. We also offer free shipping 
			for all our membership options. It is also worth noting that we currently only ship to the US.`,
			showOnRestaurantsPage: true
		},
		{
			id: 3,
			question: 'What is your refund policy?',
			answer: `We want you to be happy with our olive oil. If you try our olive oil and don’t like it, 
			we’ll ask you to return the olive oil so we can figure out what went wrong (shipping on us) and 
			issue you a refund.`,
			showOnRestaurantsPage: true
		},
		{
			id: 4,
			question: 'Why do you encourage customers to become members?',
			answer: `Our olive oil is produced on our family farm in the Dardanelles. As a single estate 
			producer, quantities are limited to what our farm can produce. To ensure those who truly 
			appreciate our olive oil can always have access, we control how many members we have and 
			make sure we have enough olive oil to supply them. There are other benefits to becoming a 
			member as well (like better pricing, free bottles …etc), but the main benefit is that we’ll 
			always have enough supply in-hand for you. `,
			showOnRestaurantsPage: false
		}
	]

	const pathname= window.location.pathname;
	
	let filteredQuestions = [...questions];
	if (pathname === '/restaurants') {
		filteredQuestions = questions.filter(question=>question.showOnRestaurantsPage)
	}

	return(
		<div className='col-lg-9 py-5 mb-2'>
			<SectionHeader><em>Frequently asked questions</em></SectionHeader>
			<FAQList questions={filteredQuestions}/>
		</div>
	)

}

export default FAQs