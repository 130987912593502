import {Fragment, useState, useContext, useEffect } from 'react';

import CartContext from '../../../store/cart-context';
import UserContext from '../../../store/user-context';
import GuestContext from '../../../store/guest-context';
import PromotionCodeContext from '../../../store/promotion-code-context';
import AffiliateContext from '../../../store/affiliate-context';

import {postData} from '../../../helpers/http-helpers';

import CartItem from './CartItem/CartItem';
import ShippingCartItem from './ShippingCartItem/ShippingCartItem';
import DollarDiscountItem from './DollarDiscountItem/DollarDiscountItem';
import InfoBanner from './InfoBanner/InfoBanner';
import SectionHeader from '../TextElements/SectionHeader/SectionHeader';
import ListItemInfoP from '../TextElements/ListItemInfoP/ListItemInfoP';
import PrimaryCTA from '../Buttons/PrimaryCTA/PrimaryCTA';
import Error from '../Error/Error';

import ReactGA from 'react-ga4';
import {eventNames, eventCategories} from '../../../helpers/analytics-helpers';

import styles from './Cart.module.css';

const Cart = props => {

	const cartCtx = useContext(CartContext);
	const userCtx = useContext(UserContext);
	const guestCtx = useContext(GuestContext);
	const promoCodeCtx = useContext(PromotionCodeContext);
	const affiliateCtx = useContext(AffiliateContext);

	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null); 

	const [itemsList, setItemsList] = useState([]);

	const [itemsToShow, setItemsToShow] = useState([]);
	const [itemsToBuy, setItemstoBuy] = useState([]);

	const adjustItemList = () => {
		const adjustedList = [...itemsList];
		adjustedList.map(item => item.quantity = cartCtx.quantities[item.id])
		return adjustedList;
	}

	const setCartItems = () => {
		const cartItems = [];
		itemsList.map(item=>{
			if (item.quantity > 0) cartItems.push(item)}
		);
		return cartItems;
	}

	const cleanCartItemsForPurchase = items => {
		const cleanedItems = []; 
		items.map(item=>{
			cleanedItems.push({
				price: item.price,
				quantity: item.quantity
			})
		});
		return cleanedItems;
	}

	const setStripePaymentMode = items => {
		const paymentMode = cartCtx.hasMembership(items) ? 'subscription' : 'payment';
		return paymentMode;
	}

	const calculateTotalPrice = () => {
		let totalPrice = 0;
		itemsList.map(item=> totalPrice += (item.displayPrice * item.quantity));
		if (!cartCtx.hasFreeShipping()) totalPrice += cartCtx.shippingPrice;
		if (promoCodeCtx.amountOff) totalPrice -= promoCodeCtx.amountOff;
		if (totalPrice % 1 !== 0) totalPrice = totalPrice.toFixed(2);
		return `$${totalPrice}`;
	}

	useEffect(()=>{
		setItemsList(adjustItemList());
		setItemsToShow(setCartItems());
	},[cartCtx]);

	useEffect(()=>{
		setItemsList([
			{
				id: 'premium',
				name: cartCtx.getItemName('premium', promoCodeCtx.percentOff),
				price: process.env.REACT_APP_PREMIUM_STRIPE_PRICE,
				listPrice: cartCtx.getItemListPrice('premium'),  
				displayPrice: promoCodeCtx.percentOff ? cartCtx.getItemDiscountedPrice('premium', promoCodeCtx.percentOff) : cartCtx.getItemListPrice('premium'),
				quantity: cartCtx.quantities.premium  
			},
			{
				id: 'luxe',
				name: cartCtx.getItemName('luxe'),
				price: process.env.REACT_APP_LUXE_STRIPE_PRICE,
				listPrice: cartCtx.getItemListPrice('luxe'),  
				displayPrice: promoCodeCtx.percentOff ? cartCtx.getItemDiscountedPrice('luxe', promoCodeCtx.percentOff) : cartCtx.getItemListPrice('luxe'),
				quantity: cartCtx.quantities.luxe  
			},
			{
				id: 'privateReserve',
				name: cartCtx.getItemName('privateReserve'),
				price: process.env.REACT_APP_PRIVATE_RESERVE_STRIPE_PRICE,
				listPrice: cartCtx.getItemListPrice('privateReserve'),  
				displayPrice: promoCodeCtx.percentOff ? cartCtx.getItemDiscountedPrice('privateReserve', promoCodeCtx.percentOff) : cartCtx.getItemListPrice('privateReserve'),
				quantity: cartCtx.quantities.privateReserve  
			},
			{
				id: 'premium750',
				name: cartCtx.getItemName('premium750'),
				price: process.env.REACT_APP_PREMIUM_750_STRIPE_PRICE,
				listPrice: cartCtx.getItemListPrice('premium750'),  
				displayPrice: promoCodeCtx.percentOff ? cartCtx.getItemDiscountedPrice('premium750', promoCodeCtx.percentOff) : cartCtx.getItemListPrice('premium750'),
				quantity: cartCtx.quantities.premium750  
			},
			{
				id: 'luxe750',
				name: cartCtx.getItemName('luxe750'),
				price: process.env.REACT_APP_LUXE_750_STRIPE_PRICE,
				listPrice: cartCtx.getItemListPrice('luxe750'),  
				displayPrice: promoCodeCtx.percentOff ? cartCtx.getItemDiscountedPrice('luxe750', promoCodeCtx.percentOff) : cartCtx.getItemListPrice('luxe750'),
				quantity: cartCtx.quantities.luxe750  
			},
			{
				id: 'privateReserve750',
				name: cartCtx.getItemName('privateReserve750'),
				price: process.env.REACT_APP_PRIVATE_RESERVE_750_STRIPE_PRICE,
				listPrice: cartCtx.getItemListPrice('privateReserve750'),  
				displayPrice: promoCodeCtx.percentOff ? cartCtx.getItemDiscountedPrice('privateReserve750', promoCodeCtx.percentOff) : cartCtx.getItemListPrice('privateReserve750'),
				quantity: cartCtx.quantities.privateReserve750  
			},
			{
				id: 'tastingCollection',
				name: 'Tasting Collection',
				price: process.env.REACT_APP_TASTING_COLLECTION_STRIPE_PRICE,
				listPrice: cartCtx.getItemListPrice('tastingCollection'),  
				displayPrice: promoCodeCtx.percentOff ? cartCtx.getItemDiscountedPrice('tastingCollection', promoCodeCtx.percentOff) : cartCtx.getItemListPrice('tastingCollection'),
				quantity: cartCtx.quantities.tastingCollection 
			},
			{
				id: 'holidayTastingCollection',
				name: 'Holiday Tasting Collection',
				price: process.env.REACT_APP_HOLIDAY_TASTING_COLLECTION_STRIPE_PRICE,
				listPrice: cartCtx.getItemListPrice('holidayTastingCollection'),  
				displayPrice: promoCodeCtx.percentOff ? cartCtx.getItemDiscountedPrice('holidayTastingCollection', promoCodeCtx.percentOff) : cartCtx.getItemListPrice('holidayTastingCollection'),
				quantity: cartCtx.quantities.holidayTastingCollection 
			},
			{
				id: 'everydayLuxury',
				name: 'Everyday Luxury',
				price: process.env.REACT_APP_EVERYDAY_LUXURY_STRIPE_PRICE,
				listPrice: cartCtx.getItemListPrice('everydayLuxury'),  
				displayPrice: promoCodeCtx.percentOff ? cartCtx.getItemDiscountedPrice('everydayLuxury', promoCodeCtx.percentOff) : cartCtx.getItemListPrice('everydayLuxury'),
				quantity: cartCtx.quantities.everydayLuxury  
			},
			{
				id: 'refinedPalate',
				name: 'Refined Palate',
				price: process.env.REACT_APP_REFINED_PALATE_STRIPE_PRICE,
				listPrice: cartCtx.getItemListPrice('refinedPalate'),  
				displayPrice: promoCodeCtx.percentOff ? cartCtx.getItemDiscountedPrice('refinedPalate', promoCodeCtx.percentOff) : cartCtx.getItemListPrice('refinedPalate'),
				quantity: cartCtx.quantities.refinedPalate  
			},
			{
				id: 'trueConnoisseur',
				name: 'True Connoisseur',
				price: process.env.REACT_APP_TRUE_CONNOISSEUR_STRIPE_PRICE,
				listPrice: cartCtx.getItemListPrice('trueConnoisseur'),  
				displayPrice: promoCodeCtx.percentOff ? cartCtx.getItemDiscountedPrice('trueConnoisseur', promoCodeCtx.percentOff) : cartCtx.getItemListPrice('trueConnoisseur'),
				quantity: cartCtx.quantities.trueConnoisseur  
			}
		]);
	},[promoCodeCtx])

	const handleCheckOut = () => {
		ReactGA.event({
    	category: eventCategories.INTENT, 
    	action: eventNames.CLICKED_CHECK_OUT,
    })
		generateCheckoutSession(itemsToShow);
	}

	const generateCheckoutSession = async items => {

	  setError(null);
	  setIsLoading(true);

	  // Customer properties
	  let customerProperties = null;
	  if (guestCtx.email) customerProperties = {customerEmail: guestCtx.email};
	  if (userCtx.email) customerProperties = {customerEmail: userCtx.email};
	  if (guestCtx.stripeCustomerId) customerProperties = {customerStripeId: guestCtx.stripeCustomerId};
		if (userCtx.stripeCustomerId) customerProperties = {customerStripeId: userCtx.stripeCustomerId};

	  // Discount handling
		let couponId = {}
		let promoCode = {}
		if (promoCodeCtx.couponId) couponId = {couponId: promoCodeCtx.couponId}
		if (promoCodeCtx.name) promoCode = {promoCode: promoCodeCtx.name}	

		//metaData
		let metaData = null;
		if (affiliateCtx.affiliateId) metaData = {metaData: {affiliate: affiliateCtx.affiliateId}};	
		
	  try {
	  	const response = await postData(
	  		`${process.env.REACT_APP_FUNCTIONS_BASE_URL}generateCheckoutSession`,
	  		{
	  			shippingPrice: cartCtx.hasFreeShipping() ? 0 : cartCtx.shippingPrice, 
	  			items: cleanCartItemsForPurchase(items), 
	  			cancelUrl: window.location.href, 
	  			mode: setStripePaymentMode(items),
	  			...customerProperties,
	  			...couponId,
	  			...promoCode,
	  			...metaData
	  		}
	    );
	    if(!response){
				throw ({message: 'Something went wrong. Please try again'});
			}
			ReactGA.event({
	    	category: eventCategories.INTENT, 
	    	action: eventNames.CHECK_OUT_CREATION_SUCCESS,
	    });

	    window.location.href = response.data.checkoutSession.url;	  
	  } catch (error) {
	  	console.log(error);
	  	setIsLoading(false);
	  	setError(error)
	  	ReactGA.event({
	    	category: eventCategories.INTENT, 
	    	action: eventNames.CHECK_OUT_CREATION_ERROR,
	    })
	  }
	}

	const cartItems = itemsToShow.map(item=><CartItem item={item} key={item.id}/>) 

	return (
		<Fragment>
			<div className='d-flex justify-content-between align-items-center mb-4'> 
				<div>
					<SectionHeader passOnClasses='mb-0'>Your cart</SectionHeader>
				</div>
				<SectionHeader passOnClasses='mb-0'>{calculateTotalPrice()}</SectionHeader>	
			</div>
			<div className={styles.ItemsWrapper}>
				{error && <Error>{error.message}</Error>}
				{(!cartCtx.hasFreeShipping() || (promoCodeCtx.couponId && cartCtx.hasDiscountElligibleItem())) && <InfoBanner />}
				{cartItems}
				<ShippingCartItem />
				{promoCodeCtx.amountOff && <DollarDiscountItem discountAmount={promoCodeCtx.amountOff}/>}
			</div>
			<PrimaryCTA 
				clickHandler={handleCheckOut}
				disabled={isLoading}
			>	
				{`Checkout - ${calculateTotalPrice()}`}
			</PrimaryCTA>
		</Fragment>
	)
}

export default Cart;