import React from 'react';
import styles from './Navbar.module.css';

import BtnLink from './BtnLink/BtnLink';
import LinkBtn from './LinkBtn/LinkBtn';
import NavLink from './NavLink/NavLink';

const Navbar = props => {

	const hideCondition = props.authenticated ? 'guests' : 'authenticated';
	const navItemsFilteredList = props.navItems.filter(item=>item.showTo !== hideCondition);
	
	const navItemsDisplayList = navItemsFilteredList.map(item=>{

		let displayItem = <p className='mb-0'>{item.copy}</p>;
		if (item.type==='btn-link') displayItem = <BtnLink isGold={props.isGold} destination={item.destination} clickHandler={item.clickHandler}>{item.copy}</BtnLink>
		if (item.type==='link') displayItem = <NavLink isGold={props.isGold} destination={item.destination} active={item.active}>{item.copy}</NavLink>
		if (item.type==='link-btn') displayItem = <LinkBtn isGold={props.isGold} clickHandler={item.clickHandler}>{item.copy}</LinkBtn>
		
		return (
			<li className={styles.ListItem}key={item.id}>
				{displayItem}
			</li>
		)
	});

	return (
		<ul className={`nav d-block d-lg-flex align-items-center ${styles.Navbar}`}>
			{navItemsDisplayList}
		</ul>
	)
}

export default Navbar;