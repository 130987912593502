import { Link } from 'react-router-dom';

import logo from '../../../assets/images/logo.webp';
import goldLogo from '../../../assets/images/gold-logo.webp';
import academyLogo from '../../../assets/images/academy/academy-logo.webp';

const Logo = props => {

	let logoToUse = logo;
	if (props.gold) logoToUse = goldLogo;
	if (props.academy) logoToUse = academyLogo;

	
	let styles = {
		backgroundImage: `url(${logoToUse})`,
		backgroundSize: 'cover',
  	backgroundRepeat: 'no-repeat',
  	height: '18px',
  	width: '184px'  
	}

	if (props.academy) {
		styles.height = '30px';
		styles.width= '282px';
	}

	if (props.small) {
		styles.height = '15px';
		styles.width= '153px';
	}

	if (props.academy && props.small) {
		styles.height = '19px';
		styles.width= '178px';
	}

	const linkTo = props.academy ? '/academy' : '/';

	return (
		<Link to={linkTo}>
			<div style={styles}></div>
		</Link>	
	)
}

export default Logo;
