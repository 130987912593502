import {Link} from 'react-router-dom';

import styles from './BtnLink.module.css';


const BtnLink = props => {
	const isGold = props.isGold ? styles.Gold : null;

	return (
		<Link to={props.destination}>
			<button className={`btn btn-primary ${styles.Button} ${isGold}`} onClick={props.clickHandler}>
				{props.children}
			</button>
		</Link>
	)
}

export default BtnLink;
