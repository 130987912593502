import downArrow from '../../../assets/images/down-arrow.webp';
import PrimaryCTA from '../../../components/UIElements/Buttons/PrimaryCTA/PrimaryCTA'
import styles from './Hero.module.css';

const Hero = props => {

	return (
		<section className={styles.Hero}>
			<div className='container'>
				<div className='row'>
					<div className='col-xl-6'>
						<h1 className={styles.Header}>
							Gift <em className='me-1'>what's</em> True
						</h1>
						<p className={styles.Tagline}>
							<span className={styles.ShortCopy}>
								This season gift your loved ones health and taste. Get our smaller sized Holiday 
								Tasting Collection. Quantities are limited.
							</span>
							<span className={styles.LongCopy}>
								This season gift your loved ones health and taste. Our Holiday Tasting Collection has smaller bottles of all three of our olive oils. Quantities are limited. Buy before we run out.
							</span>
						</p>
						<div className={`${styles.CTAContainer} ${styles.LargeCTA}`}>
							<PrimaryCTA gold xLargeSized clickHandler={props.addToCart}>
									Buy Now - $49
							</PrimaryCTA>
						</div>
						<div className={`${styles.CTAContainer} ${styles.MediumCTA}`}>
							<PrimaryCTA gold clickHandler={props.addToCart}>
									Buy Now - $49
							</PrimaryCTA>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Hero;